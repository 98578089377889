import { NextRouter } from 'next/router';

const goToPrevoiusPage = (router: NextRouter): void => {
  const prevPath = sessionStorage.getItem('prevPath');

  if (!prevPath) {
    router.push('/');
  } else {
    router.push(prevPath);
  }
};

export default goToPrevoiusPage;
