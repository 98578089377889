import { INLINES } from '@contentful/rich-text-types';
import { useRouter } from 'next/router';
import { FC } from 'react';

import HeaderTagList from '@/components/header-tag-list';
import { ArrowRightCircledFilledIcon } from '@/components/icons/arrow-icon';
import Share from '@/components/share';
import getCurrentPageShareUrl from '@/lib/get-current-page-share-url';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import goToPrevoiusPage from '@/lib/go-to-previous-page';
import useVariables from '@/lib/hooks/use-variables';
import { PageHeaderEntry } from '@/types/views/sections';

import HeaderWithBackgroundImage from '../header-with-background-image';
import LinksChipSelect from '../links-chip-select';
import RichTextRenderer, { renderInlineHyperlink } from '../rich-text-renderer';
import ActPageHeader from './act-page-header';
import HeaderImage from './header-image';
import styles from './styles';

const PageHeader: FC<Omit<PageHeaderEntry, 'id' | 'componentType'>> = ({
  title,
  description,
  image,
  background,
  backgroundMobile,
  theme,
  areActionIconsVisible = false,
  slug,
  tags,
  toggleData = [],
  sessionDate = '',
  sessionLocation = '',
  deactivateAdSlots,
}) => {
  const router = useRouter();
  const variables = useVariables();

  const activeLinkIndex = toggleData.findIndex(
    (toggleItem) => slug && toggleItem.href.includes(slug),
  );

  const urlToShare = getCurrentPageShareUrl({
    includeHash: true,
  });

  if (theme === 'act') {
    return (
      <ActPageHeader
        title={title}
        description={description}
        theme={theme}
        background={background}
        backgroundMobile={backgroundMobile}
        sessionDate={sessionDate}
        sessionLocation={sessionLocation}
        deactivateAdSlots={deactivateAdSlots}
      />
    );
  }

  return (
    <HeaderWithBackgroundImage
      background={background}
      backgroundMobile={backgroundMobile}
      alt={title}
      theme={theme}
    >
      <div className={styles.profileHeaderContainer}>
        <div className="hidden md:block absolute top-12 z-10">
          <button
            className="flex items-center"
            onClick={() => goToPrevoiusPage(router)}
          >
            <ArrowRightCircledFilledIcon
              className="transform rotate-180"
              arrowClassName="fill-black-light teair:fill-white tesn:fill-esn-secondary-dark"
              backgroundClassName="fill-white teair:fill-air-deepBlue tesn:fill-white"
            />

            <span className="text-body1 font-semibold ml-2">
              {getVariablePlainText(variables['go-back-button']) || 'Zurück'}
            </span>
          </button>
        </div>

        <div className={styles.contentContainer}>
          <HeaderImage image={image} theme={theme} title={title} />

          {toggleData.length > 1 && (
            <LinksChipSelect
              activeLinkIndex={activeLinkIndex}
              toggleLinksData={toggleData}
              className="mb-4"
            />
          )}

          <h1 className={styles.title}>{title}</h1>

          {description && (
            <div className={styles.description}>
              {typeof description === 'string' ? (
                description
              ) : (
                <RichTextRenderer
                  body={description}
                  options={{
                    renderNode: {
                      [INLINES.HYPERLINK]: renderInlineHyperlink(
                        styles.description,
                      ),
                    },
                  }}
                />
              )}
            </div>
          )}
        </div>

        {areActionIconsVisible && (
          <div className={styles.iconActionsBar}>
            {slug && <Share urlToShare={urlToShare} />}
            {/* <Like /> */}
          </div>
        )}

        {!!tags?.length && <HeaderTagList tags={tags} />}
      </div>
    </HeaderWithBackgroundImage>
  );
};

export default PageHeader;
