import cls from 'classnames';
import { useRouter } from 'next/router';
import { FC, useEffect, useRef, useState } from 'react';

import HeaderWithBackgroundImage from '@/components/header-with-background-image';
import { ArrowRightCircledFilledIcon } from '@/components/icons/arrow-icon';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import goToPrevoiusPage from '@/lib/go-to-previous-page';
import useVariables from '@/lib/hooks/use-variables';
import { formatDate } from '@/lib/utils';
import { PageHeaderEntry } from '@/types/views/sections';

import styles from '../styles';

const MAX_FONT_SIZE = 128;
const MIN_FONT_SIZE = 32;
const MIN_OFFSET_TO_CHANGE_FONT_SIZE = 8;
const SIDE_PADDINGS = 40;
const MARGIN_BELOW_HEADING = 32;

const mapToSessionDetails = (date?: string, location?: string): string => {
  const text = [];

  if (date) text.push(formatDate(date, 'dd.MM.yyyy'));

  if (location) text.push(location);

  return text.join(', ');
};

const ActPageHeader: FC<
  Omit<PageHeaderEntry, 'id' | 'componentType' | 'image'>
> = ({
  title,
  background,
  backgroundMobile,
  theme,
  sessionDate = '',
  sessionLocation = '',
  deactivateAdSlots,
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const headingOverlapSize = titleRef.current
    ? titleRef.current.offsetHeight / 2
    : undefined;
  const containerRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(128); // Initial font size

  const variables = useVariables();
  const router = useRouter();

  const splitTitle = title.split(/(?<=^\S+)\s/);

  useEffect(() => {
    const titleElement = titleRef.current;
    const titleContainer = containerRef.current;

    const adjustTitleSize = () => {
      if (titleElement && titleContainer) {
        titleElement.style.fontSize = `${fontSize}px`;

        const maxTitleWidth = titleContainer.offsetWidth - SIDE_PADDINGS;

        let newFontSize = fontSize;

        while (
          Math.abs(titleElement.offsetWidth - maxTitleWidth) >
            MIN_OFFSET_TO_CHANGE_FONT_SIZE &&
          newFontSize >= MIN_FONT_SIZE &&
          newFontSize <= MAX_FONT_SIZE
        ) {
          if (
            titleElement.offsetWidth < maxTitleWidth &&
            newFontSize < MAX_FONT_SIZE
          ) {
            newFontSize++;
          }

          if (
            titleElement.offsetWidth > maxTitleWidth &&
            newFontSize > MIN_FONT_SIZE
          ) {
            newFontSize--;
          }

          titleElement.style.fontSize = `${newFontSize}px`;
          break;
        }
        setFontSize(newFontSize);
      }
    };

    adjustTitleSize();

    window.addEventListener('resize', adjustTitleSize);

    return () => {
      window.removeEventListener('resize', adjustTitleSize);
    };
  }, [fontSize]);

  return (
    <HeaderWithBackgroundImage
      background={background}
      backgroundMobile={backgroundMobile}
      alt={title}
      theme={theme}
      adaptiveBottomMargin={
        headingOverlapSize
          ? headingOverlapSize + MARGIN_BELOW_HEADING
          : undefined
      }
    >
      <div
        className={cls(
          'content-box py-16 relative',
          deactivateAdSlots && 'ml-auto',
        )}
        ref={containerRef}
      >
        <div className="hidden md:block absolute top-12 z-10">
          <button
            className="flex items-center"
            onClick={() => goToPrevoiusPage(router)}
          >
            <ArrowRightCircledFilledIcon
              className="transform rotate-180"
              arrowClassName="fill-black-light teair:fill-white tesn:fill-esn-secondary-dark"
              backgroundClassName="fill-white teair:fill-air-deepBlue tesn:fill-white"
            />

            <span className="text-body1 font-semibold ml-2">
              {getVariablePlainText(variables['go-back-button']) || 'Zurück'}
            </span>
          </button>
        </div>
      </div>
      <div
        className={cls(
          'flex flex-col items-center justify-center',
          'absolute left-1/2 translate-x-[-50%] bottom-0 translate-y-1/2',
        )}
      >
        <h1
          ref={titleRef}
          style={{ fontSize: `${fontSize}px` }}
          className={cls(
            'font-syne uppercase text-center whitespace-nowrap font-bold leading-[1] md:leading-[0.8]',
            'tesn:text-white tels:text-white',
          )}
        >
          {splitTitle.map((titlePart) => (
            <div>{titlePart}</div>
          ))}
        </h1>

        {(sessionDate || sessionLocation) && (
          <h2 className={styles.shortInfo}>
            <span>{mapToSessionDetails(sessionDate, sessionLocation)}</span>
          </h2>
        )}
      </div>
    </HeaderWithBackgroundImage>
  );
};

export default ActPageHeader;
